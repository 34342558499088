import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import dialog from '_core_ext/dialog';

export default class Header extends Component {
    static get selector() {
        return '#header-wrapper';
    }

    get configDataAttrName() {
        return 'header-options';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);
        this._initCache();
        this._initEvents();
    }

    _initCache() {
        this.$headerEl = this.$el.find('.js-header');
        this.$userInfoDesktop = this.$el.find('.js-menu-utility-user-desktop .js-user-info');
    }

    _initEvents() {
        // for 'clickoutside' event we cant use this.event and delegation
        this.$userInfoDesktop.on('clickoutside', (element, event) => {
            this.$userInfoDesktop.removeClass('active');
        });

        this.event('focusin focusout', '.js-header-search-field', (element, event) => {
            this.$headerEl.toggleClass('searchbox-focused', event.type === 'focusin');
        });

        if (!util.isMobile()) {
            this.event('click', '.js-user-account-arnotts', (element, event) => {
                event.preventDefault();
                $(element).closest('.js-user-info').toggleClass('active');
            });
        }
    }
}

module.exports = Header;
