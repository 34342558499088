import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

export default class ImagesWrapper extends Component {
    static get selector () {
        return '.js-images-wrapper';
    }

    init (...args) {
        super.init(...args);

        eventMgr.on('variant.updated', (r) => this.updateImages(r));
    }

    updateImages(response) {
        this.$el.html($(response).find('.js-images-wrapper').html());
        app.components.initComponent('productMainImage', this.$el);
        app.components.initComponent('productCarouselImages', this.$el);
    }
}

module.exports = ImagesWrapper;
