import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class Breadcrumbs extends Component {
    static get selector() {
        return '.js-component-breadcrumbs';
    }

    get configDefault() {
        return {
            bcPlaceholder: '.js-breadcrumbs-placeholder'
        }
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.$el.removeClass('hidden');

        const hideOnMobile = SitePreferences.HIDE_BREADCRUMBS_ON_MOBILE;
        if (!hideOnMobile || (hideOnMobile && !util.isMobile())) {
            this.$el.removeClass('hidden-mobile');
            var $bcPlaceholders = $(this.config.bcPlaceholder);
            if ($bcPlaceholders.length) {
                $bcPlaceholders.empty().append(this.$el.clone());
                this.$el.addClass('bc-placeholder-present');
            }
        }
    }
}

module.exports = Breadcrumbs;
