import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
// import DD from '_core_ext/components/deviceDetector';
// import '@zeitiger/elevatezoom';
import 'jquery-touchswipe';

const emitter = eventMgr.getEmitter('productMainImage');

export default class ProductMainImage extends Component {
    static get selector() {
        return '.js-main-image';
    }

    init(...args) {
        super.init(...args);

        this.$img = this.$el.find('.js-main-image-img');
        this.$zoomImg = this.$el.find('.js-zoom-picture');
        this.$wrapper = this.$el.find('.js-main-image-wrapper');

        // FIXME: Temporary disable zoom functionality
        // this.isZoomActive = false;
        // this.initZoom();

        eventMgr.on('productImagesCarousel.changed', (i) => this.productImageChanged(i));

        this.$wrapper.swipe({
            swipeLeft: (e, d) => this.onSwipe(e, d),
            swipeRight: (e, d) => this.onSwipe(e, d),
            allowPageScroll: 'auto'
        });
    }

    productImageChanged(imageSrc) {
        this.$img.fadeOut('fast', () => {
            this.$img.attr('src', imageSrc);
            this.$img.fadeIn('fast'/*, () => this.initZoom()*/);

            this.$zoomImg.find('source').attr('srcset', imageSrc);
            this.$zoomImg.find('img').attr('src', imageSrc);

            emitter.emit('furnImageUpdated');
        });
    }

    onSwipe(event, direction) {
        switch (direction) {
            case 'right': emitter.emit('swipe', 'slickPrev'); break;
            case 'left': emitter.emit('swipe', 'slickNext'); break;
            default: break;
        }
    }

    // initZoom() {
    //     if (this.isZoomActive) {
    //         this.destroyPreviousZoom();
    //     }

    //     const deviceInfo = DD.getDeviceInfo();
    //     if (deviceInfo.device === DD.screen.DESKTOP) {
    //         this.$img.elevateZoom({
    //             zoomType: 'lens',
    //             lensShape: 'square',
    //             lensSize: 150
    //         });

    //         if (this.$el.data('elevateZoom')) {
    //             this.$el.data('elevateZoom').changeState('enable');
    //         }
    //         this.isZoomActive = true;
    //     }
    // }

    // Since elevatezoom does not have destroy method we need to destroy it manually
    // destroyPreviousZoom() {
    //     if (this.$el.data('elevateZoom')) {
    //         this.$el.data('elevateZoom').changeState('disable');
    //     }
    //     this.isZoomActive = false;
    // }
}

module.exports = ProductMainImage;
