import CustomerMigrationBase from '_core_ext/components/customer/CustomerMigration';
import dialog from '_core_ext/dialog';
import ajax from '_core_ext/ajax';
import util from '_core_ext/util';

export default class CustomerMigration extends CustomerMigrationBase {
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        this.isFormSubmitInterceptionEnabled = true;

        //call parent init method with same args
        super.init(...args);
    }

    _initEvents() {
        this.$loginForm.on('submit', () => {
            var checkerUrl = util.appendParamToURL(this.config.urls.checker, 'login', this.getFormLoginValue());
            checkerUrl = util.appendParamToURL(checkerUrl, 'source', pageContext.ns);

            ajax.getJson({
                url: checkerUrl,
                callback: (response) => {
                    if (response && response.isMigrationRequired) {
                        this.showMigrationDialog();
                    } else {
                        this.isFormSubmitInterceptionEnabled = false;
                        this.$loginFormSubmit.trigger('click');
                    }
                }
            });
            
            return !this.isFormSubmitInterceptionEnabled;
        });
    }

    showMigrationDialog() {
        var dialogUrl = util.appendParamToURL(this.config.urls.dialog, 'source', pageContext.ns);
        dialog.open({
            url: dialogUrl,
            options: {
                dialogClass: 'customer-migration-dialog',
                title: Resources.CUSTOMER_MIGRATION_DIALOG_TITLE,
                open: () => {
                    var $dialog = dialog.getCurrent();  
                    app.components.initComponent('validator');

                    $dialog.find('.js-input-email, .js-hidden-input-email').val(this.getFormLoginValue());

                    var $requestPasswordForm = $dialog.find('[name$="_requestpassword"]'),
                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');

                    $submit.on('click', function (e) {
                        if (!$requestPasswordForm.valid()) {
                            return;
                        }
                        e.preventDefault();
                        dialog.submit($submit.attr('name'));
                    });
                }
            }
        });
    }
}

module.exports = CustomerMigration;
