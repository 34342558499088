/**
 * @function
 * @description reInitializes the Back to Top button
 */
var coreScrollToTop = require('_core_ext/components/search/scrolltotop');

coreScrollToTop.createButton = function () {
    var button = $('<div/>',{
        'id': 'scrollToTop',
        'class': 'scroll-to-top',
        'text': ''
    });

    $('body').append(button);

    return button;
};

exports = coreScrollToTop;
