/** The references object of all modules needed for components initialization */
var references = {
    'ProductInfo': require('_core_ext/components/product/ProductInfo'),
    'header': require('./components/global/header'),
    'headerCheckout': require('./components/global/headerCheckout'),
    'searchsuggest': require('./components/global/searchsuggest'),
    'whatsOnCarousel': require('./components/global/whatsOnCarousel'),
    'breadcrumbs': require('./components/global/breadcrumbs'),
    'bannerCarousel': require('./components/global/bannerCarousel'),
    'brandLogosCarousel': require('./components/global/brandLogosCarousel'),
    'scrolltotop': require('./components/search/scrolltotop'),
    'mosaic': require('./components/global/mosaic'),
    'CustomerMigration': require('./components/customer/CustomerMigration'),
    'productMainImage': require('./components/product/mainimage'),
    'productCarouselImages': require('./components/product/imagescarousel'),
    'swatchSample': require('./components/product/swatchsample'),
    'imagesWrapper': require('./components/product/imageswrapper'),
    'productAttributes': require('./components/product/productattributes')
};

/** The components initialization configuration object */
var configuration = {
    'global': {
        'components': {
            'header': {},
            'headerCheckout': {},
            'searchsuggest': {},
            'whatsOnCarousel': {
                'enabled': ('slick' in $.fn)
            },
            'breadcrumbs': {},
            'bannerCarousel': {},
            'brandLogosCarousel': {},
            'mosaic': {}
        }
    },
    'account' : {
        'components': {
            'CustomerMigration' : {}
        }
    },
    'brandindex': {
        'components': {
            'scrolltotop': {}
        }
    },
    'checkout' : {
        'components': {
            'CustomerMigration' : {}
        }
    },
    'cart' : {
        'components' : {
            'minicart' : {
                'options': {
                    'disabled': true    // minicart should be disabled for user interaction, but should be initialized for calls from code
                }
            }
        }
    },
    'product': {
        'components': {
            'ProductInfo': {},
            'productMainImage': {},
            'productCarouselImages': {},
            'imagesWrapper': {},
            'swatchSample': {},
            'productAttributes': {}
        }
    },
    'search': {
        'components': {
            'scrolltotop': {},
            'productMainImage': {},
            'productCarouselImages': {},
            'imagesWrapper': {},
            'swatchSample': {}
        }
    },
    'wishlist' : {
        'components': {
            'CustomerMigration' : {}
        }
    }
};

module.exports = {
    'configuration': configuration,
    'references': references
};
