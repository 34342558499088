import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

export default class ProductAttributes extends Component {
    static get selector () {
        return '.js-product-attributes';
    }

    init (...args) {
        super.init(...args);

        eventMgr.on('variant.updated', (r) => this.updateAttributes(r));
    }

    updateAttributes(response) {
        this.$el.html($(response).find('.js-product-attributes').html());
    }
}

module.exports = ProductAttributes;
