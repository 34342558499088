import Slick from '_core_ext/components/global/slick';

export default class BrandLogosCarousel extends Slick {
    static get selector() {
        return '.js-brand-wall-carousel';
    }
    get configDefault() {
        return {
            'slidesToShow': 1,
            'slidesToScroll': 1,
            'dots': true,
            'infinite': true,
            'autoplay': false,
            'pauseOnHover': true,
            'cssEase': 'ease-in-out',
            'prevArrow': '.js-brand-wall-carousel-prev',
            'nextArrow': '.js-brand-wall-carousel-next',
            'customPaging': function(slider, i) {
                return $('<button type="button" data-role="none" role="button" tabindex="0"><span class="aricons-dots"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></button>').append(i + 1);
            },
            'adaptiveHeight': true
        };
    }
    get configDataAttrName() {
        return 'component-brand-wall-carousel';
    }
}

module.exports = BrandLogosCarousel;
