import Slick from '_core_ext/components/global/slick';
import util from '_core_ext/util';

export default class BannerCarousel extends Slick {
    static get selector() {
        return '.js-component-banner-carousel';
    }
    get configDefault() {
        return {
            'dots': true,
            'infinite': true,
            'autoplay': false,
            'autoplaySpeed': 7000,
            'pauseOnHover': true,
            'speed': 1000,
            'fade': true,
            'cssEase': 'ease-in-out',
            'prevArrow': '.js-banner-carousel-prev',
            'nextArrow': '.js-banner-carousel-next',
            'customPaging': function(slider, i) {
                return $('<button type="button" data-role="none" role="button" tabindex="0"><span class="aricons-dots"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></button>').append(i + 1);
            },
            'adaptiveHeight': true
        };
    }
    get configDataAttrName() {
        return 'component-banner-carousel';
    }
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.$window = $(window);

        this._setArrowsPositionOnMobile();
        this._initEvents();
    }

    _initEvents() {
        if (!util.isMobile()) {
            this.event('click', '.js-banner-carousel-next', (element, event) => {
                event.preventDefault();
                this.$el.slick('slickNext');
            });
            this.event('click', '.js-banner-carousel-prev', (element, event) => {
                event.preventDefault();
                this.$el.slick('slickPrev');
            });
        }

        this.$window.on('orientationchange', this._setArrowsPositionOnMobile.bind(this));
    }

    _setArrowsPositionOnMobile() {
        // init arrows top position on '.plp-promo-title' level on mobile
        if (!util.isDesktop()) {
            var $promoWrapper = this.$el.find('.promo-content-wrapper').first(),
                promoContentOffsetTop = $promoWrapper[0].offsetTop,
                promoTitleOffsetTop = $promoWrapper.find('.plp-promo-title')[0].offsetTop;

            this.$el.find('.slick-arrow').css('top', promoContentOffsetTop + promoTitleOffsetTop + 14);
        }
    }
}

module.exports = BannerCarousel;
