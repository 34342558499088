import Slick from '_core_ext/components/global/slick';
import util from '_core_ext/util';

export default class WhatsOnCarousel extends Slick {
    static get selector() {
        return '.js-component-whats-on-carousel';
    }
    get configDefault() {
        return {
            'dots': false,
            'infinite': true,
            'autoplay': true,
            'autoplaySpeed': 7000,
            'pauseOnHover': true,
            'speed': 1000,
            'fade': true,
            'cssEase': 'ease-in-out',
            'prevArrow': '',
            'nextArrow': '',
            'responsive': [{
                'breakpoint': 768,
                'settings': {
                    'dots': true,
                    'prevArrow': '<button type="button" class="slick-prev js-slick-button"><span class="aricons-arrow-big"><span class="path1"></span><span class="path2"></span></span></button>',
                    'nextArrow': '<button type="button" class="slick-next js-slick-button"><span class="aricons-arrow-big"><span class="path1"></span><span class="path2"></span></span></button>'
                }
            }],
            'customPaging': function(slider, i) {
                return $('<button type="button" data-role="none" role="button" tabindex="0" class="js-slick-button"><span class="aricons-dots"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></button>').append(i + 1);
            },
            'adaptiveHeight': true
        };
    }
    get configDataAttrName() {
        return 'component-whats-on-carousel';
    }
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initEvents();
    }
    _initEvents() {
        if (!util.isMobile()) {
            this.event('click', '.js-whats-on-carousel-next', (element, event) => {
                event.preventDefault();
                this.$el.slick('slickNext');
            });
            this.event('click', '.js-whats-on-carousel-prev', (element, event) => {
                event.preventDefault();
                this.$el.slick('slickPrev');
            });
            this.event('click', '.js-whats-on-carousel-page-el', (element, event) => {
                event.preventDefault();
                var pageIndex = parseInt($(element).data('pageEl'), 10);
                if (pageIndex >= 0) {
                    this.$el.slick('slickGoTo', parseInt(pageIndex, 10));
                }
            });
        }

        this.$window.on('orientationchange resize', () => {
            util.throttle(this.reInit, 200, this);
        });
    }
    reInit () {
        if (this.$el.hasClass('slick-initialized')) {
            this.$el.slick('unslick');
            // Remove extra buttons and pagination that was added
            this.$el.find('.js-slick-button').remove();
            this.$el.slick(this.config);
        }
    }
}

module.exports = WhatsOnCarousel;
