import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

require('waypoints/lib/jquery.waypoints.min');

/* global Waypoint */

export default class Mosaic extends Component {
    static get selector() {
        return '.js-component-mosaic';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.mouseCoord = {x: 0, y: 0};
        this.newMouseCoord = {x: 0, y: 0};
        this.rotateY = 0;
        this.rotateX = 0;

        this._initCache();
        this._initEvents();
        this._listen();
        this._waypoint();
    }

    _initCache() {
        this.$reference = this.$el.find('.js-mosaic-receptor').first();
        this.$window = $(window);
    }

    _initEvents() {
        if (!util.isTouch()) {
            this.event('mousemove', (element, event) => {
                const contextWidth = this.$window.width() / 2;
                const contextHeight = this.$window.height() / 2;

                this.mouseCoord.x = 1 - (event.clientX / contextWidth);
                this.mouseCoord.y = 1 - (event.clientY / contextHeight);
            });
        }
    }

    _listen() {
        requestAnimationFrame(() => {
            this._listen();
        });

        this.newMouseCoord.x += (this.mouseCoord.x - this.newMouseCoord.x) / 10;
        this.newMouseCoord.y += (this.mouseCoord.y - this.newMouseCoord.y) / 10;

        const newRotateY = this.newMouseCoord.x * -20;
        const newRotateX = this.newMouseCoord.y * 10;

        if (this.rotateX !== newRotateX || this.rotateY !== newRotateY) {
            this.rotateX = newRotateX;
            this.rotateY = newRotateY
            const transformValue = 'rotateY(' + this.rotateY + 'deg) rotateX(' + this.rotateX + 'deg)';

            this.$reference.css({
                '-webkit-transform': transformValue,
                '-moz-transform': transformValue,
                '-ms-transform': transformValue,
                '-o-transform': transformValue,
                'transform': transformValue
            });
        }
    }

    _waypoint() {
        const waypoint = new Waypoint({
            element: this.$el,
            handler: (direction) => {
                if (direction === 'down') {
                    this.$el.addClass('mosaic-waypoint');
                }
            },
            offset: '50%'
        });

        return waypoint;
    }
}

module.exports = Mosaic;
