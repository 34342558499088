import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

export default class SwatchSample extends Component {
    static get selector() {
        return '.js-swatch-sample';
    }

    get configDataAttrName() {
        return 'swatch-sample';
    }

    init(...args) {
        super.init(...args);
        this.$addToBag = this.$el.find('.js-swatch-add-form');
        this.$editBag = this.$el.find('.js-already-added');

        eventMgr.execute('components.initialized').then(() => this.checkForAlreadyAddedItem(this.config.pid));
        eventMgr.on('variant.updated', () => this.checkForAlreadyAddedItem(this.config.pid));
        eventMgr.on('minicart.updated', () => this.checkForAlreadyAddedItem(this.config.pid));
    }

    checkForAlreadyAddedItem(ID) {
        const minicartData = app.components.get('minicart').getMiniCartData();

        if (minicartData) {
            const items = minicartData.items;
            let added = false;

            for (let i = 0; i < items.length && !added; i++) {
                if (items[i].productId === ID) {
                    added = true;
                }
            }

            if (added === true) {
                this.$addToBag.addClass('visually-hidden');
                this.$editBag.removeClass('visually-hidden');
            }
        }
    }
}

module.exports = SwatchSample;
