import Slick from '_core_ext/components/global/slick';
import DD from '_core_ext/components/deviceDetector';
import $ from 'jquery';
import eventMgr from '_core_ext/eventMgr';
import _debounce from 'lodash/function/debounce';

const emitter = eventMgr.getEmitter('productImagesCarousel'),
    SLIDES_TO_SHOW_DESKTOP = 4,
    SLIDES_TO_SHOW_MOBILE = 1;

export default class ProductCarouselImages extends Slick {
    static get selector() {
        return '.js-carousel-images';
    }

    get configDefault() {
        return {
            'dots': false,
            'infinite': true,
            'autoplay': false,
            'fade': false,
            'rows': 0,
            'cssEase': 'ease-in-out',
            'prevArrow': false,
            'lazyLoad': 'ondemand'
        };
    }

    get configDataAttrName() {
        return 'component-slick';
    }

    init(...args) {
        const $document = $(document),
            $window = $(window);
        this.deviceInfo = DD.getDeviceInfo();
        this.setBehaviour(this.config.imagesCount, this.deviceInfo.device);

        super.init(...args);

        this.$items = this.$el.find('.js-carousel-images-item');

        this.$items.on('click', (event) => this.onClick(event));
        this.$el.on('beforeChange', (e, s, c, n) => this.onSlideChange(e, s, c, n));
        eventMgr.on('productMainImage.swipe', (d) => this.onImageSwipe(d));
        $document.ready(() => this.$el.removeClass('visually-hidden'));
        $window.on('resize', _debounce(() => this.onDeviceChange(), 300));
    }

    /**
     * Triggering when user swipe main image
     * @param {string} direction 
     */
    onImageSwipe(direction) {
        this.$el.slick(direction);
    }

    /**
     * Triggering when user click on thumbnail image in carousel 
     * to change main image
     * @param {Event} event 
     */
    onClick(event) {
        const $target = $(event.currentTarget);
        this.$el.slick('slickGoTo', $target.data('slickIndex'));
        emitter.emit('changed', $target.data('lgImage'));
    }

    /**
     * Triggering when user click on carousel arrow
     * @param {Event} event 
     * @param {Slick} slick 
     * @param {number} currentSlide 
     * @param {number} nextSlide 
     */
    onSlideChange(event, slick, currentSlide, nextSlide) {
        const $item = $(slick.$slides.get(nextSlide));
        emitter.emit('changed', $item.data('lgImage'));
    }

    /**
     * Set carousel behaviour based on images count and device
     * @param {number} imagesCount 
     * @param {string} device
     */
    setBehaviour(imagesCount, device) {
        const slidesToShow = device === DD.screen.MOBILE ? SLIDES_TO_SHOW_MOBILE : SLIDES_TO_SHOW_DESKTOP;

        if (imagesCount <= slidesToShow) {
            this.$el.addClass('furniture-slider__nav-slider--less-count');
            this.config.nextArrow = false;
        } else {
            this.config.nextArrow = this.buildArrow(this.config.arrowClass);
            this.$el.removeClass('furniture-slider__nav-slider--less-count');
        }

        this.config.slidesToShow = slidesToShow;
        this.config.prevArrow = false;        
    }

    /**
     * @param {string} arrowClass 
     * @returns {string}
     */
    buildArrow(arrowClass) {
        return `<button type="button" class="${arrowClass}">
                    <span></span>
                </button>`;
    }

    /**
     * Triggering when device change orientation or window resize
     */
    onDeviceChange() {
        this.deviceInfo = DD.getDeviceInfo();
        if (this.$el.hasClass('slick-initialized')) {
            this.$el.slick('unslick');
        }

        this.setBehaviour(this.config.imagesCount, this.deviceInfo.device);
        this.$el.slick(this.config);
    }

    // override parent method.
    unSlickHandler () {}
}

module.exports = ProductCarouselImages;

