import _debounce from 'lodash/function/debounce';

function addBodyAdditionalClasses() {
    var $window = $(window);
    var $body = $('body');
    var hasVerticalScrollBar = false;

    function windowHasVerticalScrollBar() {
        return $body.height() > $window.height();
    }
    
    hasVerticalScrollBar = windowHasVerticalScrollBar();
    $body.toggleClass('has-vertical-scrollbar', hasVerticalScrollBar);

    function resizingDone() {
        hasVerticalScrollBar = windowHasVerticalScrollBar();
        $body.toggleClass('has-vertical-scrollbar', hasVerticalScrollBar);
    }

    $(window).resize(_debounce(resizingDone, 500));
}

// general extension functions
module.exports = function () {
    addBodyAdditionalClasses();
};
