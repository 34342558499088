import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class HeaderCheckout extends Component {
    static get selector() {
        return '.js-top-banner-wrapper';
    }

    get configDataAttrName() {
        return 'header-options';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);
        this._initCache();
        this._initEvents();
        this._updateStickyClass();
    }

    _initCache() {
        this.$window = $(window);
        this.isDesktop = util.isDesktop();
        this.isTablet = util.isTablet();
        this.isLandscape = util.isLandscape();
    }

    _initEvents() {
        this.$window.on('orientationchange', this._orientationChanged.bind(this));

        this.$window.on('scroll', this._updateStickyClass.bind(this));
    }

    _orientationChanged() {
        this.isLandscape = util.isLandscape();
        this._updateStickyClass();
    }

    _updateStickyClass() {
        if (this.isDesktop || (this.isTablet && this.isLandscape)) {
            if (this.$window.scrollTop() > this.config.vertThreshDesktop) {
                if (!this.$el.hasClass('sticky')) {
                    this.$el.addClass('sticky');
                }
            } else if (this.$el.hasClass('sticky')) {
                this.$el.removeClass('sticky');
            }
        }
    }
}

module.exports = HeaderCheckout;
