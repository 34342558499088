import Component from '_core_ext/components/Component';
import searchsuggest from '_core_ext/searchsuggest-beta';

const HIDDEN_CLASS = 'hidden';

export default class SearchSuggest extends Component {
    static get selector() {
        return '#header-search-form-wrapper';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initEvents();
    }

    _initEvents() {
        this.$searchSubmit = this.$el.find('.js-header-search-submit');
        this.$searchReset = this.$el.find('.js-header-search-reset');

        this.event('click', '.js-search-suggestion-close', () => {
            searchsuggest.clearResults();
        });

        this.event('click', '.js-header-search-reset', () => {
            this.$searchReset.addClass(HIDDEN_CLASS);
            this.$searchSubmit.removeClass(HIDDEN_CLASS);
        });
    }
}

module.exports = SearchSuggest;
